<template>
	<div>
		<CourseRecruitmentForm />
	</div>
</template>

<script>
import CourseRecruitmentForm from '@/components/templates/CourseRecruitmentForm';

export default {
	components: {
		CourseRecruitmentForm
	}
}
</script>